import * as React from "react";
import Layout from "../../components/Layout";
import { graphql } from "gatsby";
import { useSiteMetadata } from "../../utilities/siteMetadataHook";
import TopicPageHero from "../../components/topicComponents/TopicPageHero";
import TopicBookCards from "../../components/topicComponents/TopicBookCards";

const TopicPage = ({ data: topicInfo, location }) => {
  if (topicInfo.hasOwnProperty("topicInfo")) {
    topicInfo = topicInfo.topicInfo;
    if (topicInfo && topicInfo.hasOwnProperty("data")) {
      topicInfo = topicInfo.data;
    }
  }
  // console.log(location);

  const { title } = useSiteMetadata();

  return (
    <Layout
      pageTitle={
        topicInfo ? `Books on ${topicInfo.Topics} - ${title}` : `${title}`
      }
      description={
        topicInfo && topicInfo.topics_description
          ? topicInfo.topics_description
          : null
      }
      location={location.pathname}
    >
      {topicInfo && <TopicPageHero data={topicInfo} />}
      <div className="topic-page-booklist flex flex-col gap-4 mt-6 md:mt-12">
        {topicInfo && topicInfo.book_data_complete ? (
          topicInfo.book_data_complete.map(book => (
            <div key={book.id}>
              <TopicBookCards data={book.data} />
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    topicInfo: airtable(id: { eq: $id }, table: { eq: "book_topics" }) {
      id
      data {
        Topics
        topics_description
        topic_covers {
          localFiles {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        book_data_complete {
          data {
            authors
            bookTitle
            Topics__from_topic_tags_
            bookCover {
              localFiles {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            description
            descriptionRewritten
          }
          id
        }
      }
    }
  }
`;

export default TopicPage;
