import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const TopicPageHero = ({ data }) => {
  return (
    <div className="topic-page-hero card rounded-3xl bg-base-300 px-5 py-5 flex-col md:flex-row items-center gap-3 shadow-lg">
      <div className="md:w-1/2">
        {data.topic_covers &&
        data.topic_covers.localFiles[0].childImageSharp ? (
          <GatsbyImage
            image={
              data.topic_covers.localFiles[0].childImageSharp.gatsbyImageData
            }
            className="rounded-xl"
            objectFit="contain"
            alt={data.Topics}
            formats={["auto", "webp", "avif"]}
          />
        ) : (
          <StaticImage
            className="rounded-xl"
            alt="book-image"
            height={240}
            src="../../images/topics_card_placeholder.jpg"
            formats={["auto", "webp", "avif"]}
          />
        )}
      </div>
      <div className="card-body md:w-1/2">
        <div className="flex flex-row gap-2 items-center">
          <div className="avatar placeholder">
            <div className="bg-neutral-focus text-neutral-content rounded-full w-8 h-8">
              <span className="text-xs">{data.Topics[0]}</span>
            </div>
          </div>
          <h1 className="card-title">{data.Topics}</h1>
        </div>
        <div>
          <p className="card-body-description">
            {data.topics_description
              ? data.topics_description
              : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopicPageHero;
